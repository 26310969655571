@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: values.$colorWhite;
  border-radius: values.$radius6;
  border: values.$line0-5 solid transparent;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: values.$space6;
  padding: values.$space4 values.$space2;
  min-height: 170px;
  @include mediaqueries.smallUp {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: values.$space4;
    min-height: 132px;
  }
  @include mediaqueries.mediumUp {
    padding: values.$space6 values.$space3 values.$space1 values.$space6;
    min-height: 160px;
  }

  &:hover,
  &:focus-visible {
    outline: values.$line0-5 solid values.$colorRed500;
    box-shadow: 0px 0px px 0px rgba(2, 13, 39, 0.20), 0px 2px 12px 0px rgba(2, 13, 39, 0.10);
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @include mediaqueries.smallUp {
    flex-direction: row;
    align-items: flex-start;
  }

  &__serviceIcon {
    order: 1;
    width: 76px;
    height: 76px;
    @include mediaqueries.smallUp {
      order: 4;
      width: 90px;
      height: 90px;
      flex: 1 0 auto;
    }
    @include mediaqueries.mediumUp {
      width: 119px;
      height: 119px;
    }
  }
}

.label {
  @include typography.labelMTypographyStyles;
  color: values.$colorNeutral800;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  order: 2;

  @include mediaqueries.upToSmall {
    text-align: center;
    margin-bottom: 0;
  }

  @include mediaqueries.smallUp {
    flex: 1 0 100%;
    order: 1;
  }
}

.price {
  @include typography.paragraphSTypography;
  color: values.$colorNeutral600;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  order: 3;
  text-align: center;

  @include mediaqueries.smallUp {
    @include typography.paragraphMTypography;
    font-weight: 500;
    text-align: left;
    flex: 0 1 calc(100% - 90px);
    order: 1;
  }
  @include mediaqueries.mediumUp {
    flex: 0 1 calc(100% - 119px);
  }
}

.arrowLink {
  color: values.$colorWhite;
  background-color: values.$colorRed500;
  margin-top: values.$space3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: block;
  order: 3;
  position: absolute;
  bottom: values.$space4;
  left: values.$space4;
  @include mediaqueries.mediumUp {
    bottom: values.$space2-5;
    left: values.$space6;
  }
  @include mediaqueries.upToSmall {
    display: none;
  }

  > :global(.qvIcon) {
    position: relative;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
  }
}
