@use '$styles/theme' as *;

$-headlineFontFamily: if($themeName == "motrio", "Poppins", Jokker);
$-displayFontFamily: if($themeName == "motrio", "Poppins", Jokker);
$-labelFontFamily: if($themeName == "motrio", "Poppins", Jokker);
$-paragraphFontFamily: if($themeName == "motrio", "Poppins", Rubik);
$-campaignFontFamily: if($themeName == "motrio", "Poppins", Champ);

// DISPLAYS
$displayM: normal normal 600 80px/80px $-displayFontFamily, sans-serif;

// HEADLINES
$headlinesFontFamily: $-headlineFontFamily;
$headlinesH1: normal normal 600 56px/56px $-headlineFontFamily, sans-serif;
$headlinesH2: normal normal 600 40px/44px $-headlineFontFamily, sans-serif;
$headlinesH3: normal normal 600 32px/40px $-headlineFontFamily, sans-serif;
$headlinesH4: normal normal 600 28px/32px $-headlineFontFamily, sans-serif;
$headlinesH5: normal normal 600 24px/28px $-headlineFontFamily, sans-serif;
$headlinesH6: normal normal 600 22px/28px $-headlineFontFamily, sans-serif;

// LABELS
$labelFontFamily: $-headlineFontFamily;
$labelL: normal normal 500 18px/24px $-headlineFontFamily, sans-serif;
$labelM: normal normal 600 16px/20px $-headlineFontFamily, sans-serif;
$labelS: normal normal 600 14px/18px $-headlineFontFamily, sans-serif;
$labelXS: normal normal 700 12px/16px $-headlineFontFamily, sans-serif;

// PARAGRAPH
$paragraphFontFamily: $-paragraphFontFamily;
$paragraphL: normal normal 400 18px/28px $-paragraphFontFamily, sans-serif;
$paragraphM: normal normal 400 16px/24px $-paragraphFontFamily, sans-serif;
$paragraphS: normal normal 400 14px/20px $-paragraphFontFamily, sans-serif;
$paragraphXS: normal normal 400 12px/20px $-paragraphFontFamily, sans-serif;

// CAMPAIGN
$campaignFontFamily: $-campaignFontFamily;

// NEW tokens
$fontDisplayM: $displayM;
$fontFamilyHeadlines: $headlinesFontFamily;
$fontHeadlinesH1: $headlinesH1;
$fontHeadlinesH2: $headlinesH2;
$fontHeadlinesH3: $headlinesH3;
$fontHeadlinesH4: $headlinesH4;
$fontHeadlinesH5: $headlinesH5;
$fontHeadlinesH6: $headlinesH6;
$fontFamilyLabel: $labelFontFamily;
$fontLabelL: $labelL;
$fontLabelM: $labelM;
$fontLabelS: $labelS;
$fontLabelXS: $labelXS;
$fontFamilyParagraph: $paragraphFontFamily;
$fontParagraphL: $paragraphL;
$fontParagraphM: $paragraphM;
$fontParagraphS: $paragraphS;
$fontParagraphXS: $paragraphXS;
$fontFamilyCampaign: $campaignFontFamily;
$fontCampaignL: normal normal 700 80px/110% $-campaignFontFamily, sans-serif;
$fontCampaignM: normal normal 700 46px/110% $-campaignFontFamily, sans-serif;
$fontCampaignS: normal normal 600 40px/110% $-campaignFontFamily, sans-serif;
$fontCampaignXS: normal normal 600 24px/125% $-campaignFontFamily, sans-serif;
