@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';

.component {
    background: values.$colorNeutral100;
    padding: values.$space8 0;
}

.title {
    margin-bottom: values.$space7 !important;
}